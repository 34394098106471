<template>
  <fr-button :icon="theme.icon" size="xs">
    <q-tooltip> Theme picker </q-tooltip>

    <span class="hidden md:block">
      {{ theme?.label }} {{ themeStyle?.label }}
    </span>

    <q-menu
      class="m-0 min-w-[140px] p-0 shadow-none"
      transition-show="jump-down"
      transition-duration="400"
      :offset="[0, 5]"
    >
      <fr-card>
        <q-list separator :dark="$q.dark.isActive">
          <div class="fr-card-header font-bold tracking-wider">
            <span> Dark Mode </span>
          </div>
          <template v-for="mode in modes" :key="mode.type">
            <q-item
              clickable
              :class="
                theme.type === mode.type ? 'font-semibold text-cyan-500' : ''
              "
              @click="() => setTheme(mode)"
            >
              <q-item-section>{{ mode.label }}</q-item-section>
            </q-item>
          </template>
        </q-list>

        <q-list separator :dark="$q.dark.isActive">
          <div class="fr-card-header !rounded-none font-bold tracking-wider">
            <span>Theme Style</span>
          </div>

          <template v-for="style in styles" :key="style.type">
            <q-item
              clickable
              :class="
                themeStyle.type === style.type
                  ? 'font-semibold text-cyan-500'
                  : ''
              "
              @click="() => setThemeStyle(style)"
            >
              <q-item-section>{{ style.label }}</q-item-section>
            </q-item>
          </template>
        </q-list>
      </fr-card>
    </q-menu>
  </fr-button>
</template>

<script setup lang="ts">
import useAuthStore from '~/features/auth/store'
import { Dark } from 'quasar'
import factories from '~/factories'
import { modes, styles } from './theme-switch'

const authStore = useAuthStore()

const user = useUser()

const theme = computed({
  get: () => authStore.theme,
  set: (value) => (authStore.theme = value)
})

const themeStyle = computed({
  get: () => authStore.themeStyle,
  set: (value) => (authStore.themeStyle = value)
})

const setTheme = (payload: (typeof modes)[0]) => {
  theme.value = payload

  if (user.value?.id) {
    updateUserTheme({
      id: user.value?.id as number,
      themeColor: theme.value.type
    })
  }
}

const setThemeStyle = (payload: (typeof styles)[0]) => {
  themeStyle.value = payload

  if (user.value?.id) {
    updateUserTheme({
      id: user.value?.id as number,
      themeStyle: themeStyle.value.type
    })
  }
}

/**
 * Hooks
 */
const { mutateAsync: updateUserTheme } =
  factories.admin.mutations.useUpdateUserTheme()

/**
 * Watchers
 */
watch(
  () => theme.value,
  (val) => {
    if (val) {
      const isDark = val.type === 'dark'

      Dark.set(isDark)
      if (isDark) {
        document.querySelector('html')?.classList.add('dark')
      } else {
        document.querySelector('html')?.classList.remove('dark')
      }
    }
  },
  { immediate: true }
)

watch(
  () => themeStyle.value,
  (val) => {
    if (val) {
      if (val.type === 'neo') {
        document.querySelector('html')?.classList.add('neo')
        document.querySelector('html')?.classList.remove('chowey')
      } else {
        document.querySelector('html')?.classList.add('chowey')
        document.querySelector('html')?.classList.remove('neo')
      }
    }
  },
  { immediate: true }
)
</script>
